const { Buffer } = require('buffer')

const isBase64 = str => {
  const base64regex =
    /^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$/
  return base64regex.test(str)
}

function encodeHTML(string) {
  if (!string) return null

  return Buffer.from(string).toString('base64')
}

function doDecodeHTML(string) {
  return isBase64(string) ? Buffer.from(string, 'base64').toString() : string
}

function decodeHTML(string) {
  if (!string) return null

  // fallback 1
  const stringToDecode = string.startsWith('%3C') ? decodeURI(string) : string

  // fallback 2
  return string.startsWith('&#60;')
    ? string.replace(/&#([0-9]{1,4});/gi, function (_, num) {
        return String.fromCharCode(parseInt(num))
      })
    : doDecodeHTML(stringToDecode)
}

module.exports = {
  encodeHTML,
  decodeHTML,
}
