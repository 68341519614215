<template>
  <div id="module-selection" data-test-module-selection>
    <ItemSelector
      ref="moduleInput"
      :current-value="moduleSeleted"
      :watch-current="true"
      :view-config="{ highlightQuantity: _menuOptionsModules[0].items.length }"
      :input-config="{
        label: $t('PermissionsPage.selectModule.title'),
        showAvatar: false,
        closeOnSelect: true,
      }"
      :menu-config="{
        attach: true,
        showTabs: false,
      }"
      :menu-options="_menuOptionsModules"
      :local-search="true"
      :persistent="true"
      @update:item="submit"
    ></ItemSelector>
  </div>
</template>

<script>
import * as _permissions from '@/helpers/ability/permissions'

export default {
  name: 'ModuleSelection',
  data() {
    return {
      moduleSeleted: {
        data: [],
        origin: 'modules',
      },
    }
  },
  computed: {
    _menuOptionsModules() {
      return [
        {
          value: 'modules',
          label: this.$t('PermissionsPage.selectModule.listModules.label'),
          type: 'listview',
          items: this._listModulesItems,
        },
      ]
    },

    _listModulesItems() {
      const permissions = [
        {
          permission: _permissions.management_permissions_module_profile,
          tabs: [_permissions.management_permissions_permission_groups],
          id: 'profile',
          label: this.$t(
            'PermissionsPage.selectModule.listModules.items.profile'
          ),
        },
        {
          permission: _permissions.management_permissions_module_engagement,
          tabs: [_permissions.management_permissions_permission_groups],
          id: 'engagement',
          label: this.$t(
            'PermissionsPage.selectModule.listModules.items.engagement'
          ),
        },
        {
          permission: _permissions.management_permissions_module_performance,
          tabs: [
            _permissions.management_permissions_permission_groups,
            _permissions.management_performance_group_roles,
            _permissions.management_performance_entities_access_profiles,
          ],
          id: 'performance',
          label: this.$t(
            'PermissionsPage.selectModule.listModules.items.performance'
          ),
        },
        {
          permission: _permissions.management_permissions_module_growth,
          tabs: [_permissions.management_permissions_permission_groups],
          id: 'growth',
          label: this.$t(
            'PermissionsPage.selectModule.listModules.items.growth'
          ),
        },
        {
          permission: _permissions.management_permissions_module_configuration,
          tabs: [_permissions.management_permissions_permission_groups],
          id: 'management',
          label: this.$t(
            'PermissionsPage.selectModule.listModules.items.management'
          ),
        },
        {
          permission: _permissions.management_permissions_module_notification,
          tabs: [_permissions.management_permissions_permission_groups],
          id: 'notifications',
          label: this.$t(
            'PermissionsPage.selectModule.listModules.items.notification'
          ),
        },
        {
          permission: _permissions.management_permissions_module_custom_survey,
          tabs: [_permissions.management_permissions_permission_groups],
          id: 'custom-survey',
          label: this.$t(
            'PermissionsPage.selectModule.listModules.items.customSurvey'
          ),
        },
        {
          permission: _permissions.management_permissions_module_rewards,
          tabs: [_permissions.management_permissions_permission_groups],
          id: 'rewards',
          label: this.$t(
            'PermissionsPage.selectModule.listModules.items.rewards'
          ),
        },
      ]

      return permissions
        .filter(({ permission, tabs }) => {
          const hasPermission = this.$can('access', permission)
          if (!hasPermission) return false

          return tabs.some(tab => this.$can('access', tab))
        })
        .map(({ id, label }) => ({
          id,
          label,
        }))
    },
  },
  mounted() {
    this.setDefaultModule()
  },
  methods: {
    submit(value) {
      this.$emit('setValueModule', value)
    },

    setDefaultModule() {
      const items = this._listModulesItems
      const moduleDefault =
        items.find(el => el.id === 'performance') || items?.[0]

      if (!moduleDefault?.id) {
        return this.submit(null)
      }

      this.moduleSeleted = {
        data: [moduleDefault],
        origin: 'modules',
      }

      this.submit(moduleDefault)
    },

    forceValue(moduleID) {
      const moduleSelected = this._listModulesItems.find(
        el => el.id === moduleID
      )

      const payload = {
        data: [moduleSelected],
        origin: 'modules',
      }

      this.$refs.moduleInput.setCurrentValue(payload)
    },
  },
}
</script>

<style lang="scss" scoped src="./style.scss" />
