<template>
  <div id="permissions-page" data-test-id="permissions-page">
    <v-card class="card">
      <div class="header">
        <div class="header-title" data-test-header-title>
          <h1>{{ $t('PermissionsPage.title') }}</h1>

          <HowToBeginAndWatchAVideo
            video-type="listPermissionGroup"
            short
            :has-video="false"
          ></HowToBeginAndWatchAVideo>
        </div>

        <div class="header-subtitle">
          <h3>{{ $t('PermissionsPage.subtitle') }}</h3>
        </div>
      </div>

      <section class="content-header">
        <div class="rows">
          <v-row>
            <v-col cols="12" sm="8" md="6" lg="5">
              <ModuleSelection
                ref="moduleSelection"
                data-test-module-selection
                @setValueModule="handleModule($event)"
              ></ModuleSelection>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              v-show="moduleSeleted === 'performance'"
              cols="12"
              sm="6"
              md="5"
              lg="3"
            >
              <CycleSelection
                ref="cycleSelection"
                @change:cycle="handleCycle($event)"
              ></CycleSelection>
            </v-col>

            <v-col class="template-section" cols="12" sm="6" md="7" lg="9">
              <TemplatesMenu
                ref="templatesMenu"
                :templates="templateList"
                @get-templates="getTemplates()"
                @compare-template="selectTemplate($event, true)"
                @delete-template="handleDeleteTemplate($event)"
                @edit-template="editTemplate($event)"
                @submit="selectTemplate($event)"
                @alert="displayAlert($event)"
              ></TemplatesMenu>
            </v-col>
          </v-row>
        </div>
      </section>

      <section class="content-header">
        <div class="tab-content">
          <v-tabs v-model="tab" height="2.375rem" show-arrows>
            <v-tab
              v-for="(item, i) in _tabs"
              :key="i"
              class="tab-label"
              :disabled="loading.content || loading.save"
              :data-test-tab-permission="item"
              :href="'#' + item"
            >
              {{ $t(`PermissionsPage.tabs.${item}.label`) }}
              <v-menu
                content-class="menu"
                max-width="18.75rem"
                open-on-hover
                open-on-click
                top
                :nudge-top="10"
                :nudge-left="150"
                offset-y
              >
                <template #activator="{ on, attrs }">
                  <i
                    v-bind="attrs"
                    data-test-default-info
                    class="fi-rr-interrogation"
                    v-on="on"
                    @click.stop.prevent="handleHelp(item)"
                  ></i>
                </template>

                <v-card
                  class="menu-info"
                  width="18.75rem"
                  data-test-menu-info
                  flat
                >
                  <div class="title">
                    {{ $t(`PermissionsPage.tabs.${item}.label`) }}
                  </div>

                  <div class="label">
                    {{ $t(`PermissionsPage.tabs.${item}.help`) }}
                  </div>
                </v-card>
              </v-menu>
            </v-tab>
          </v-tabs>

          <v-spacer></v-spacer>

          <PermissionFilter
            :module-seleted="moduleSeleted"
            :tab-seleted="tab"
            @filter:permission:values="setValueFilters"
          ></PermissionFilter>
        </div>
      </section>

      <section v-if="_tabs.length > 0" class="content-body">
        <div v-if="loading.content">
          <v-skeleton-loader type="table-tbody"></v-skeleton-loader>
        </div>
        <div v-for="(item, i) in _tabs" v-show="item === tab" :key="i">
          <ToggleTable
            v-if="data[item].columns.length && data[item].types.length"
            v-show="!loading.content"
            :ref="`toggle-table-${item}`"
            :columns="data[item].columns"
            :types="data[item].types"
            :right-bar="_rightBar"
            :show-settings="_permissionsEdit"
            :readonly="!_permissionsEdit"
            :show-visible-btn="
              moduleSeleted === 'performance' && _permissionsEdit
            "
            :visible-label="{
              show: $t('PermissionsPage.visibleLabels.show', {
                tab: $t(`PermissionsPage.tabs.${item}.label`).toLowerCase(),
              }),
              hide: $t('PermissionsPage.visibleLabels.hide', {
                tab: $t(`PermissionsPage.tabs.${item}.label`).toLowerCase(),
              }),
            }"
            :comparison="data[item].comparison"
            @change="handleChangedArray()"
            @handle-visible="handleVisible($event)"
            @handle-right-action="handleCreate()"
            @handle-settings="handleSettings"
          ></ToggleTable>

          <div
            v-if="
              tab === item && !data?.[tab]?.columns?.length && !loading.content
            "
            data-test-empty-state
            class="empty"
          >
            <span class="empty-label">
              {{ $t('PermissionsPage.empty.text1') }}
              {{ $t(`PermissionsPage.tabs.${item}.label`) }} <br />
              {{ $t('PermissionsPage.empty.text2') }}
            </span>

            <v-btn
              class="text-none primary-btn"
              depressed
              @click="handleCreate()"
              >+
              {{ _rightBar.label }}
            </v-btn>
          </div>
        </div>
      </section>

      <section v-if="data?.[tab]?.columns?.length" class="content-footer">
        <v-spacer></v-spacer>

        <v-btn
          class="text-none text-btn"
          :loading="loading.save"
          text
          data-test-cancel-changes
          @click="restoreChanges(true)"
        >
          {{ $t('PermissionsPage.footer.cancel') }}
        </v-btn>

        <v-btn
          class="text-none primary-btn"
          :loading="loading.save"
          depressed
          data-test-save-template
          @click="openModalAddNewTemplate()"
        >
          {{ $t('PermissionsPage.footer.saveTemplate') }}
        </v-btn>

        <v-btn
          class="text-none primary-btn"
          :loading="loading.save"
          depressed
          data-test-apply-permissions
          @click="applyPermissions()"
        >
          {{ $t('PermissionsPage.footer.apply') }}
        </v-btn>
      </section>
    </v-card>

    <AddNewGroupRole
      ref="AddNewGroupRole"
      :module-seleted="moduleSeleted"
      :tab-seleted="'groups-roles'"
      @success="handleAddColumn($event)"
      @alert="displayAlert($event)"
    ></AddNewGroupRole>

    <AddNewPermissionsGroup
      ref="AddNewPermissionsGroup"
      :module-seleted="moduleSeleted"
      :tab-seleted="'groups-roles'"
      @success="handleAddColumn($event)"
      @alert="displayAlert($event)"
    ></AddNewPermissionsGroup>

    <AddNewEntityRole
      ref="AddNewEntityRole"
      data-test-add-new-entity-role
      @success="handleAddColumn($event)"
      @alert="displayAlert($event)"
    ></AddNewEntityRole>

    <EditGroupRole
      ref="EditGroupRole"
      :module-seleted="moduleSeleted"
      :tab-seleted="'groups-roles'"
      :cycle-seleted="selectedCycle"
      @success="handleEditColumn($event)"
      @alert="displayAlert($event)"
      @delete="openModalDeleteGroupRole"
    ></EditGroupRole>

    <EditPermissionsGroup
      ref="EditPermissionsGroup"
      :module-seleted="moduleSeleted"
      :tab-seleted="'permission-groups'"
      :cycle-seleted="selectedCycle"
      @success="handleEditColumn($event)"
      @alert="displayAlert($event)"
      @delete="openModalDeletePermissionsGroup"
    ></EditPermissionsGroup>

    <EditEntityRole
      ref="EditEntityRole"
      data-test-edit-entity-role
      :module-seleted="moduleSeleted"
      :cycle-seleted="selectedCycle"
      @success="handleEditColumn($event)"
      @alert="displayAlert($event)"
      @delete="openModalDeleteEntityRole"
    ></EditEntityRole>

    <AddNewTemplate
      ref="AddNewTemplate"
      data-test-add-new-template
      :template-list="templateList"
      @submit="saveTemplate($event)"
    ></AddNewTemplate>

    <ApplyPermissions
      ref="ApplyPermissions"
      @saveChanges="saveChanges()"
    ></ApplyPermissions>

    <DeleteRole
      ref="DeleteRole"
      @deleteRoleSubmit="deleteRoleSubmit"
    ></DeleteRole>

    <SaveChangesRequest
      ref="saveChangesRequest"
      @submit="handleChangeRequest($event)"
    ></SaveChangesRequest>

    <DeleteTemplate
      ref="DeleteTemplate"
      @open-close-template-menu="openCloseTemplateMenu($event)"
      @deleteTemplateSubmit="deleteTemplateSubmit($event)"
    ></DeleteTemplate>

    <AlertBar ref="AlertBar"></AlertBar>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { getPermissions, updatePermissions } from '@/services/permissions'
import { deleteGroupsRoles, deleteEntityRole } from '@/services/papers'
import { deletePermissionGroups } from '@/services/permission-groups'
import {
  newTemplate,
  getTemplates,
  updateTemplate,
  deleteTemplate,
} from '@/services/template-permissions'
import { getHierarchySettings } from '@/services/entities'
import { alertErrorMessage, alertSuccessMessage } from '@/helpers/alerts'
import {
  management_permissions_permission_groups,
  management_performance_group_roles,
  management_performance_entities_access_profiles,
  management_permissions_create,
  management_performance_group_roles_create,
  management_performance_entities_access_profiles_create,
  management_permissions_edit,
  management_performance_group_roles_edit,
  management_performance_entities_access_profiles_edit,
} from '@/helpers/ability'
import ModuleSelection from './Partials/ModuleSelection/ModuleSelection.vue'
import CycleSelection from './Partials/CycleSelection/CycleSelection.vue'
import PermissionFilter from './Partials/PermissionFilter/PermissionFilter.vue'
import SaveChangesRequest from './Partials/SaveChangesRequest/SaveChangesRequest.vue'
import ApplyPermissions from './Partials/Modals/ApplyPermissions/ApplyPermissions.vue'
import DeleteRole from './Partials/Modals/DeleteRole/DeleteRole.vue'
import AddNewPermissionsGroup from './Partials/Modals/AddNewPermissionsGroup/AddNewPermissionsGroup.vue'
import EditPermissionsGroup from './Partials/Modals/EditPermissionsGroup/EditPermissionsGroup.vue'
import AddNewGroupRole from './Partials/Modals/AddNewGroupRole/AddNewGroupRole.vue'
import EditGroupRole from './Partials/Modals/EditGroupRole/EditGroupRole.vue'
import AddNewEntityRole from './Partials/Modals/AddNewEntityRole/AddNewEntityRole.vue'
import EditEntityRole from './Partials/Modals/EditEntityRole/EditEntityRole.vue'
import TemplatesMenu from './Partials/TemplatesMenu/TemplatesMenu.vue'
import AddNewTemplate from './Partials/Modals/AddNewTemplate/AddNewTemplate.vue'
import DeleteTemplate from './Partials/Modals/DeleteTemplate/DeleteTemplate.vue'

export default {
  name: 'PermissionsPage',
  components: {
    ModuleSelection,
    CycleSelection,
    PermissionFilter,
    SaveChangesRequest,
    AddNewPermissionsGroup,
    AddNewGroupRole,
    AddNewEntityRole,
    ApplyPermissions,
    EditEntityRole,
    DeleteRole,
    EditGroupRole,
    EditPermissionsGroup,
    TemplatesMenu,
    AddNewTemplate,
    DeleteTemplate,
  },

  data() {
    return {
      hierarchy: [],
      loading: {
        content: true,
        save: false,
      },
      gettingPerm: false,
      filters: {},
      moduleSeleted: '',
      selectedCycle: '',
      templateList: [],
      tab: '',
      tabs: ['permission-groups', 'groups-roles', 'access-profiles'],
      data: {
        'permission-groups': {
          types: [],
          columns: [],
          changedItems: [],
          template: {},
          comparison: [],
        },
        'groups-roles': {
          types: [],
          columns: [],
          changedItems: [],
          template: {},
          comparison: [],
        },
        'access-profiles': {
          types: [],
          columns: [],
          changedItems: [],
          template: {},
          comparison: [],
        },
      },
      keys: {
        'permission-groups': 'permissionGroups',
        'groups-roles': 'groupsRoles',
        'access-profiles': 'accessProfiles',
      },
      templateKeys: {
        'permission-groups': 'permissionGroupsPermissions',
        'groups-roles': 'groupsRolesPermissions',
        'access-profiles': 'accessProfilesPermissions',
      },
    }
  },

  computed: {
    _tabs() {
      switch (this.moduleSeleted) {
        case 'profile':
        case 'growth':
        case 'engagement':
        case 'management':
        case 'notifications':
        case 'custom-survey':
        case 'rewards':
          return [...(this._canPermissionGroups ? [this.tabs[0]] : [])]

        default:
          return [
            ...(this._canPermissionGroups ? [this.tabs[0]] : []),
            ...(this._canGroupsRoles ? [this.tabs[1]] : []),
            ...(this._canAccessProfiles ? [this.tabs[2]] : []),
          ]
      }
    },
    _permissionsCreate() {
      switch (this.tab) {
        case 'permission-groups':
          return this.$can('access', management_permissions_create)

        case 'groups-roles':
          return this.$can('access', management_performance_group_roles_create)

        case 'access-profiles':
          return this.$can(
            'access',
            management_performance_entities_access_profiles_create
          )
      }
      return false
    },

    _rightBar() {
      return {
        show: this._permissionsCreate,
        icon: 'fi-br-plus-small',
        label: this.$t(`PermissionsPage.add.${this.tab}`),
      }
    },

    _canPermissionGroups() {
      return this.$can('access', management_permissions_permission_groups)
    },

    _canGroupsRoles() {
      return this.$can('access', management_performance_group_roles)
    },

    _canAccessProfiles() {
      return this.$can(
        'access',
        management_performance_entities_access_profiles
      )
    },

    _permissionsEdit() {
      switch (this.tab) {
        case 'permission-groups':
          return this.$can('access', management_permissions_edit)
        case 'groups-roles':
          return this.$can('access', management_performance_group_roles_edit)
        case 'access-profiles':
          return this.$can(
            'access',
            management_performance_entities_access_profiles_edit
          )
      }
      return false
    },
  },

  watch: {
    tab(newVal, oldVal) {
      this.fetchHierarchy()

      if (Object.keys(this.filters).length) {
        this.data[oldVal].columns = []
        this.setValueFilters({})
        return
      }

      if (!this.data[newVal].columns.length) {
        this.getPermissions()
      }
    },
  },

  mounted() {
    this.setCurrentPage('PermissionsPage')
    this.setVisibleFilterLateral(false)
  },

  beforeDestroy() {
    this.setVisibleFilterLateral(true)
  },

  methods: {
    ...mapActions(['setCurrentPage', 'setVisibleFilterLateral']),

    handleCreate() {
      if (this.tab === 'permission-groups') this.handleAddNewPermissionsGroup()
      if (this.tab === 'groups-roles') this.handleAddNewGroupRole()
      if (this.tab === 'access-profiles') this.handleAddNewEntityRole()
    },

    handleAddNewPermissionsGroup() {
      if (this.$refs.AddNewPermissionsGroup) {
        this.$refs.AddNewPermissionsGroup.handleOpenDialog()
      }
    },

    handleAddNewGroupRole() {
      if (this.$refs.AddNewGroupRole) {
        this.$refs.AddNewGroupRole.handleOpenDialog()
      }
    },

    handleAddNewEntityRole() {
      if (this.$refs.AddNewEntityRole) {
        this.$refs.AddNewEntityRole.handleOpenDialog()
      }
    },

    handleEditPermissionsGroup(PermissionsGroupPayload = { id: null }) {
      if (this.$refs.EditPermissionsGroup) {
        this.$refs.EditPermissionsGroup.handleOpenDialog(
          PermissionsGroupPayload
        )
      }
    },

    handleEditGroupRole(GroupRolePayload = { id: null }) {
      if (this.$refs.EditGroupRole) {
        this.$refs.EditGroupRole.handleOpenDialog(GroupRolePayload)
      }
    },

    handleEditEntityRole(entityRolePayload = { id: null }) {
      if (this.$refs.EditEntityRole) {
        this.$refs.EditEntityRole.handleOpenDialog(entityRolePayload)
      }
    },

    async saveChanges() {
      this.loading.save = true

      let payload = {}

      this._tabs.forEach(tab => {
        if (this.data[tab].changedItems.length) {
          // fetch tab permission array
          payload = {
            ...payload,
            [this.keys[tab]]: [],
          }

          this.data[tab].changedItems.forEach(el => {
            // setting on local data
            const columnIndex = this.data[tab].columns.findIndex(
              col => col.id === el.columnID
            )

            this.data[tab].columns[columnIndex].values[el.type] = el.value

            // handle payload object
            const typeIndex = this.data[tab].types.findIndex(
              type => type.value === el.type
            )

            const isContext = typeIndex >= 0

            const contextKey = isContext
              ? this.data[tab].types[typeIndex].value
              : this.data[tab].types.find(type =>
                  type.children.some(child => child.value === el.type)
                ).value

            let payloadIndex = payload[this.keys[tab]].findIndex(
              payload => payload.id === el.columnID
            )

            // insert new permission object
            if (payloadIndex < 0) {
              payload[this.keys[tab]].push({
                id: el.columnID,
                label: this.data[tab].columns[columnIndex].label,
                active: this.data[tab].columns[columnIndex].visible,
                contexts: [],
              })

              payloadIndex = payload[this.keys[tab]].findIndex(
                payload => payload.id === el.columnID
              )
            }

            let contextIndex = payload[this.keys[tab]][
              payloadIndex
            ].contexts.findIndex(cont => cont.context === contextKey)

            if (contextIndex < 0) {
              // add new context only if needed
              payload[this.keys[tab]][payloadIndex].contexts.push({
                context: contextKey,
                permissions: [],
              })

              contextIndex = contextIndex = payload[this.keys[tab]][
                payloadIndex
              ].contexts.findIndex(cont => cont.context === contextKey)
            }

            // is permission
            if (!isContext) {
              // set permission on context
              payload[this.keys[tab]][payloadIndex].contexts[
                contextIndex
              ].permissions.push({
                permission: el.type,
                active: el.value,
                ...(el.type.includes('metadata-permission') && {
                  id: el.type.replace('metadata-permission-', ''),
                }),
              })
            }
          })
        }
      })

      if (Object.keys(payload).length) {
        payload = {
          module: this.moduleSeleted,
          ...(this.moduleSeleted === 'performance' && {
            cycleID: this.selectedCycle || null,
          }),
          ...payload,
        }

        await this.updatePermissions(payload)
      }

      this.loading.save = false
    },

    async updatePermissions(payload) {
      await updatePermissions(payload)
        .then(() => {
          this.clearChanged()

          this.handleAlert({
            messageAlert: this.$t('PermissionsPage.successChange'),
            statusAlert: 'success',
          })
        })
        .catch(error => {
          const alertPayload = alertErrorMessage(
            this.$t('PermissionsPage.errorChange'),
            error
          )

          this.displayAlert(alertPayload)
        })
    },

    async fetchHierarchy() {
      if (this.tab !== 'access-profiles') return
      if (this.hierarchy.length) return

      try {
        const { data } = await getHierarchySettings()
        this.hierarchy = this.transformArray(data)
      } catch (error) {
        const alertPayload = alertErrorMessage(
          this.$t('PermissionsPage.getHierarchyError'),
          error
        )

        this.displayAlert(alertPayload)
      }
    },

    async getPermissions() {
      if (!this.moduleSeleted) return
      if (this.gettingPerm) return

      try {
        this.gettingPerm = true
        this.loading.content = true

        const payload = {
          type: this.tab,
          params: {
            module: this.moduleSeleted,
            ...(this.moduleSeleted === 'performance' && {
              cycleID: this.selectedCycle || null,
            }),
            ...this.filters,
          },
        }

        await getPermissions(payload).then(({ data }) => {
          if (!this.data[this.tab].types.length) {
            this.data[this.tab].types = data.contexts.map(el => ({
              value: el.context,
              label: el.label,
              expanded: false,
              children: el.permissions.map(perm => ({
                value: perm.permission,
                label: perm.label,
              })),
              ...(!!el.id && { id: el.id }),
            }))
          }

          this.data[this.tab].columns = data[this.keys[this.tab]].map(el => ({
            id: el.id,
            label: el.label,
            hint: el.entityType
              ? `${this.$t(`PermissionsPage.hints.${el.entityType}`)} ${
                  el.entityClass
                    ? this.$t(`PermissionsPage.hints.${el.entityClass}`)
                    : ''
                }`
              : '',
            ...(this.moduleSeleted === 'performance' && { visible: el.active }),
            fixed: false,
            deletable: el.deletable,
            ...(el.entityType && { entityType: el.entityType }),
            ...(el.entityClass && { entityClass: el.entityClass }),
            values: this.handleValues(el.contexts),
          }))
        })
      } catch (err) {
        const alertPayload = alertErrorMessage(
          this.$t('PermissionsPage.getError'),
          err
        )

        this.displayAlert(alertPayload)
      } finally {
        this.loading.content = false
        this.gettingPerm = false
      }
    },

    async getTemplates() {
      const params = {
        module: this.moduleSeleted,
      }

      await getTemplates(params)
        .then(({ data }) => {
          this.templateList = data
          this.$refs.templatesMenu.setTemplateList(data)
        })
        .catch(err => {
          const alertPayload = alertErrorMessage(
            this.$t('PermissionsPage.addNewTemplate.alert.errorGet'),
            err
          )

          this.displayAlert(alertPayload)
        })
    },

    handleChangedArray() {
      this.data[this.tab].changedItems =
        this.$refs[`toggle-table-${this.tab}`][0].getChanged()

      if (
        this.data[this.tab].template &&
        Object.keys(this.data[this.tab].template).length
      ) {
        this.$refs.templatesMenu.clearSelected()
        this.data[this.tab].template = {}
      }
    },

    handleModule(event) {
      if (event.id === this.moduleSeleted) {
        return
      }

      if (this.data?.[this.tab]?.changedItems?.length) {
        return this.$refs.saveChangesRequest.open({
          key: 'moduleSeleted',
          ...event,
        })
      }

      this.setModule(event)
    },

    setModule(event) {
      this.moduleSeleted = event.id
      this.tab = this._tabs[0]

      this.clearData()
      this.getPermissions()
      this.$refs.templatesMenu.clearSelected()
    },

    handleCycle(event) {
      if (event.id === this.selectedCycle) {
        return
      }

      if (this.data[this.tab].changedItems.length) {
        return this.$refs.saveChangesRequest.open({
          key: 'selectedCycle',
          ...event,
        })
      }

      this.setCycle(event)
    },

    setCycle(event) {
      this.selectedCycle = event.id

      this.clearData()
      this.getPermissions()
    },

    async handleChangeRequest(event) {
      if (event.action === 'apply') {
        await this.saveChanges()

        if (event.key === 'moduleSeleted') this.setModule(event)
        if (event.key === 'selectedCycle') this.setCycle(event)

        return
      }

      if (event.key === 'moduleSeleted') {
        if (event.action === 'cancel')
          this.$refs.moduleSelection.forceValue(this.moduleSeleted)
        if (event.action === 'not-apply') this.setModule(event)

        return
      }

      if (event.key === 'selectedCycle') {
        if (event.action === 'cancel')
          this.$refs.cycleSelection.forceValue(this.selectedCycle)
        if (event.action === 'not-apply') this.setCycle(event)
      }

      this.$emit('handle-change', event.action)
    },

    setValueFilters(event) {
      const filters = {
        ...event,
        ...(Array.isArray(event?.hierarchySettingsID) && {
          hierarchySettingsID: event.hierarchySettingsID[0],
        }),
        ...(Array.isArray(event?.active) && {
          active: event.active[0] === 'true',
        }),
      }

      if (JSON.stringify(filters) === JSON.stringify(this.filters)) return

      this.data[this.tab].columns = []

      this.filters = filters

      this.getPermissions()
    },

    handleHelp(value) {
      return this.$t(`PermissionsPage.tabs.${value}.help`)
    },

    handleVisible(event) {
      const columnIndex = this.data[this.tab].columns.findIndex(
        el => el.id === event.columnID
      )

      this.data[this.tab].columns[columnIndex].visible = event.visible

      const payload = {
        module: this.moduleSeleted,
        ...(this.moduleSeleted === 'performance' && {
          cycleID: this.selectedCycle || null,
        }),
        [this.keys[this.tab]]: [
          {
            id: event.columnID,
            label: this.data[this.tab].columns[columnIndex].label,
            active: this.data[this.tab].columns[columnIndex].visible,
            contexts: [],
          },
        ],
      }

      this.updatePermissions(payload)
    },

    handleAlert(event) {
      const config = {
        messagePrefix: '',
        description: event.messageAlert,
        type: event.statusAlert,
        hasLeftBorder: false,
        hasFooter: false,
      }

      this.displayAlert(config)
    },

    applyPermissions() {
      this.$refs.ApplyPermissions.handleOpenDialog()
    },

    displayAlert(payload) {
      if (this.$refs.AlertBar) {
        this.$refs.AlertBar.displayAlert(payload)
      }
    },

    deleteRoleSubmit(type, id) {
      switch (type) {
        case 'groupRole':
          this.deleteGroupsRoles(id)
          break
        case 'entityRole':
          this.deleteEntityRole(id)
          break
        case 'permissionGroup':
          this.deletePermissionGroups(id)
          break
      }
    },

    openModalDeletePermissionsGroup(PermissionsGroupPayload) {
      this.$refs.DeleteRole.handleOpenDialog(
        'permissionGroup',
        PermissionsGroupPayload
      )
    },
    openModalDeleteGroupRole(GroupRolePayload) {
      this.$refs.DeleteRole.handleOpenDialog('groupRole', GroupRolePayload)
    },

    openModalDeleteEntityRole(entityRolePayload) {
      this.$refs.DeleteRole.handleOpenDialog('entityRole', entityRolePayload)
    },

    async deleteGroupsRoles(rolesID) {
      await deleteGroupsRoles(rolesID)
        .then(() => {
          const alertPayload = alertSuccessMessage(
            this.$t('PermissionsPage.deleteRole.groupRole.alert.success')
          )

          this.displayAlert(alertPayload)
          this.$refs.EditGroupRole.$refs.modal.dialog = false

          this.handleDeleteColumn({ id: rolesID }, 'groups-roles')
        })
        .catch(error => {
          const alertPayload = alertErrorMessage(
            this.$t('PermissionsPage.deleteRole.groupRole.alert.error'),
            error
          )

          this.displayAlert(alertPayload)
        })
    },

    async deleteEntityRole(rolesID) {
      await deleteEntityRole(rolesID)
        .then(() => {
          const alertPayload = alertSuccessMessage(
            this.$t('PermissionsPage.deleteRole.entityRole.alert.success')
          )

          this.displayAlert(alertPayload)
          this.$refs.EditEntityRole.$refs.modal.dialog = false

          this.handleDeleteColumn({ id: rolesID }, 'access-profiles')
        })
        .catch(error => {
          const alertPayload = alertErrorMessage(
            this.$t('PermissionsPage.deleteRole.entityRole.alert.error'),
            error
          )

          this.displayAlert(alertPayload)
        })
    },

    async deletePermissionGroups(rolesID) {
      await deletePermissionGroups(rolesID)
        .then(() => {
          const alertPayload = alertSuccessMessage(
            this.$t('PermissionsPage.deleteRole.permissionGroup.alert.success')
          )

          this.displayAlert(alertPayload)
          this.$refs.EditPermissionsGroup.$refs.modal.dialog = false

          this.handleDeleteColumn({ id: rolesID }, 'permission-groups')
        })
        .catch(error => {
          const alertPayload = alertErrorMessage(
            this.$t('PermissionsPage.deleteRole.permissionGroup.alert.error'),
            error
          )

          this.displayAlert(alertPayload)
        })
    },

    restoreChanges(actionButtonCancel = false) {
      this._tabs.forEach(tab => {
        if (this.data[tab].columns.length) {
          let componentColumns = this.$refs[`toggle-table-${tab}`][0].allColumns

          this.data[tab].columns.forEach(el => {
            const index = componentColumns.findIndex(col => col.id === el.id)
            this.$refs[`toggle-table-${tab}`][0].allColumns[index].values =
              structuredClone(el.values)
          })
        }
      })

      if (actionButtonCancel) {
        this.$refs.templatesMenu.clearSelected()
      }

      this.clearChanged()
    },

    clearChanged() {
      this._tabs.forEach(tab => {
        if (this.data[tab].columns.length && this.data[tab].types.length) {
          this.$refs[`toggle-table-${tab}`][0].clearChanged()
        }

        this.data[tab].changedItems = []
      })
    },

    clearData() {
      this._tabs.forEach(tab => {
        if (this.data[tab].columns.length && this.data[tab].types.length) {
          this.$refs?.[`toggle-table-${tab}`]?.[0]?.clearChanged()
        }

        this.data[tab] = {
          types: [],
          columns: [],
          permissions: [],
          changedItems: [],
        }
      })
    },

    handleValues(arr) {
      const newObj = {}

      arr.forEach(item => {
        const values = item.permissions.map(el => el.active)
        newObj[item.context] = values.every(value => value === true)

        item.permissions.forEach(permission => {
          newObj[permission.permission] = permission.active
        })
      })

      return newObj
    },

    handleSettings(id) {
      switch (this.tab) {
        case 'permission-groups':
          this.handleEditPermissionsGroup(id)
          break
        case 'groups-roles':
          this.handleEditGroupRole(id)
          break
        case 'access-profiles':
          this.handleEditEntityRole(id)
          break
      }
    },

    processEntity(entity, parentType, parentClass, resultArray) {
      const { entityType, entityClass, entityAllowedChildren } = entity
      const newObj = {
        entityParentType: parentType,
        entityParentClass: parentClass,
        entityType,
        entityClass,
      }

      if (newObj.entityParentType != null && newObj.entityParentClass != null)
        resultArray.push(newObj)

      if (entityAllowedChildren && entityAllowedChildren.length > 0) {
        entityAllowedChildren.forEach(child => {
          this.processEntity(child, entityType, entityClass, resultArray)
        })
      }
    },

    transformArray(inputArray) {
      const resultArray = []

      inputArray.forEach(entity => {
        this.processEntity(entity, null, null, resultArray)
      })

      return resultArray
    },

    async findIndexInsert(columns, newColumn, parentColumn, vm) {
      let indiceInserir = null
      let column = newColumn

      if (parentColumn != null) {
        column = {
          entityClass: parentColumn.entityParentClass,
          entityType: parentColumn.entityParentType,
        }
      }

      for (let i = columns.length - 1; i >= 0; i--) {
        if (
          vm.data[vm.tab].columns[i].entityType === column.entityType &&
          (vm.data[vm.tab].columns[i].entityClass === column.entityClass ||
            !Object.prototype.hasOwnProperty.call(
              vm.data[vm.tab].columns[i],
              'entityClass'
            ))
        ) {
          indiceInserir = i + 1
          break
        }
      }

      if (indiceInserir != null) return indiceInserir

      const objPai = await vm.findEntityByTypeAndClass(
        vm.hierarchy,
        column.entityType ? column.entityType : null,
        column.entityClass ? column.entityClass : null
      )

      return this.findIndexInsert(vm.data[vm.tab].columns, null, objPai, vm)
    },

    async handleAddColumn(event) {
      const payload = {
        type: this.tab,
        params: {
          module: this.moduleSeleted,
          ...(this.moduleSeleted === 'performance' && {
            cycleID: this.selectedCycle || null,
          }),
          label: event.label,
        },
      }

      try {
        const { data } = await getPermissions(payload)

        const element = data[this.keys[this.tab]].find(el => el.id === event.id)

        if (!element) return

        const permission = {
          id: element.id,
          label: element.label,
          hint: element.entityType
            ? `${this.$t(`PermissionsPage.hints.${element.entityType}`)} ${
                element.entityClass
                  ? this.$t(`PermissionsPage.hints.${element.entityClass}`)
                  : ''
              }`
            : '',
          ...(this.moduleSeleted === 'performance' && {
            visible: element.active,
          }),
          fixed: false,
          deletable: element.deletable,
          ...(element.entityType && { entityType: element.entityType }),
          ...(element.entityClass && { entityClass: element.entityClass }),
          values: this.handleValues(element.contexts),
        }

        if (this.tab != 'access-profiles') {
          this.data[this.tab].columns.push(permission)

          this.$refs[`toggle-table-${this.tab}`][0].allColumns =
            structuredClone([
              ...this.$refs[`toggle-table-${this.tab}`][0].allColumns,
              permission,
            ])

          setTimeout(() => {
            this.scrollToEnd()
          }, 500)
        }

        if (this.tab === 'access-profiles') {
          let index = await this.findIndexInsert(
            this.data[this.tab].columns,
            permission,
            null,
            this
          )
          this.data[this.tab].columns.splice(index, 0, permission)

          this.$refs[`toggle-table-${this.tab}`][0].allColumns.splice(
            index,
            0,
            permission
          )
        }
      } catch (error) {
        const alertPayload = alertErrorMessage(
          this.$t('PermissionsPage.getError'),
          error
        )

        this.displayAlert(alertPayload)
      }
    },

    findEntityByTypeAndClass(arrayHierarchy, entityType, entityClass) {
      for (const entity of arrayHierarchy) {
        if (
          entity?.entityType === entityType &&
          entity?.entityClass === entityClass
        ) {
          return entity
        }
      }
      return null
    },

    handleEditColumn(event) {
      const colIndex = this.data[this.tab].columns.findIndex(
        el => el.id === event.id
      )

      this.data[this.tab].columns[colIndex].label = event.label

      this.$refs[`toggle-table-${this.tab}`][0].allColumns[colIndex].label =
        event.label

      if (this.moduleSeleted === 'performance') {
        this.data[this.tab].columns[colIndex].visible = event.active
        this.$refs[`toggle-table-${this.tab}`][0].allColumns[colIndex].visible =
          event.active
      }
    },

    handleDeleteColumn(event) {
      const colIndex = this.data[this.tab].columns.findIndex(
        el => el.id === event.id
      )

      this.data[this.tab].columns.splice(colIndex, 1)
      this.$refs[`toggle-table-${this.tab}`][0].allColumns.splice(colIndex, 1)
    },

    scrollToEnd() {
      const index = this._tabs.findIndex(el => el === this.tab)
      const scrollGrids = document.getElementsByClassName('hz-scroll')

      if (scrollGrids.length > 0) {
        const scrollGrid = scrollGrids[index]
          ? scrollGrids[index]
          : scrollGrids[index - 1]
        scrollGrid.scrollLeft = scrollGrid.scrollWidth
      }
    },

    openModalAddNewTemplate() {
      this.getTemplates()
      this.$refs.AddNewTemplate.handleOpenDialog()
    },

    async saveTemplate(event) {
      this.loading.save = false

      let data = structuredClone(this.data)
      let payload = {}

      this.tabs.forEach(tab => {
        // fetch tab permission array
        payload = {
          ...payload,
          [this.templateKeys[tab]]: [],
        }

        // setting on local method data
        data[tab].changedItems.forEach(el => {
          const columnIndex = data[tab].columns.findIndex(
            col => col.id === el.columnID
          )

          data[tab].columns[columnIndex].values[el.type] = el.value
        })

        // travel columns
        data[tab].columns.forEach(el => {
          for (const key of Object.keys(el.values)) {
            // handle payload object
            const typeIndex = data[tab].types.findIndex(
              type => type.value === key
            )

            const isContext = typeIndex >= 0

            const contextKey = isContext
              ? data[tab].types[typeIndex].value
              : data[tab].types.find(type =>
                  type.children.some(child => child.value === key)
                ).value

            let payloadIndex = payload[this.templateKeys[tab]].findIndex(
              payload => payload.id === el.id
            )

            // insert new permission object
            if (payloadIndex < 0) {
              payload[this.templateKeys[tab]].push({
                id: el.id,
                label: el.label,
                active: el.visible,
                contexts: [],
              })

              payloadIndex = payload[this.templateKeys[tab]].findIndex(
                payload => payload.id === el.id
              )
            }

            let contextIndex = payload[this.templateKeys[tab]][
              payloadIndex
            ].contexts.findIndex(cont => cont.context === contextKey)

            if (contextIndex < 0) {
              // add new context only if needed
              payload[this.templateKeys[tab]][payloadIndex].contexts.push({
                context: contextKey,
                permissions: [],
              })

              contextIndex = contextIndex = payload[this.templateKeys[tab]][
                payloadIndex
              ].contexts.findIndex(cont => cont.context === contextKey)
            }

            // is permission
            if (!isContext) {
              // set permission on context
              payload[this.templateKeys[tab]][payloadIndex].contexts[
                contextIndex
              ].permissions.push({
                permission: key,
                active: el.values[key] || false,
                ...(key.includes('metadata-permission') && {
                  id: key.replace('metadata-permission-', ''),
                }),
              })
            }
          }
        })
      })

      payload.module = this.moduleSeleted

      if (event.typeRequest === 'new') {
        payload.label = event.form.nameNewTemplate

        await this.newTemplate(payload)
      } else {
        const templateID = event.form.replaceTemplate.id

        delete event.form.replaceTemplate.id

        await this.updateTemplate(
          templateID,
          event.form.replaceTemplate,
          payload
        )
      }

      this.getTemplates()
      this.loading.save = false
    },

    async newTemplate(payload) {
      await newTemplate(payload)
        .then(() => {
          this.handleAlert({
            messageAlert: this.$t(
              'PermissionsPage.addNewTemplate.alert.successAdd'
            ),
            statusAlert: 'success',
          })
        })
        .catch(error => {
          const alertPayload = alertErrorMessage(
            this.$t('PermissionsPage.addNewTemplate.alert.errorAdd'),
            error
          )

          this.displayAlert(alertPayload)
        })
    },

    async updateTemplate(templateID, selectedTemplate, body) {
      const payload = {
        templateID: templateID,
        body: {
          ...selectedTemplate,
          ...body,
        },
      }

      await updateTemplate(payload)
        .then(() => {
          this.handleAlert({
            messageAlert: this.$t(
              'PermissionsPage.addNewTemplate.alert.successUpdate'
            ),
            statusAlert: 'success',
          })
        })
        .catch(error => {
          const alertPayload = alertErrorMessage(
            this.$t('PermissionsPage.addNewTemplate.alert.errorUpdate'),
            error
          )

          this.displayAlert(alertPayload)
        })
    },
    resetTemplates() {
      this.data['permission-groups'].template = {}
      this.data['groups-roles'].template = {}
      this.data['access-profiles'].template = {}
      this.restoreChanges()
    },
    selectTemplate(event, comparison = false) {
      this.resetTemplates()
      if (!event) {
        if (comparison) {
          this.data['permission-groups'].comparison = []
          this.data['groups-roles'].comparison = []
          this.data['access-profiles'].comparison = []
        }

        return
      }

      this._tabs.forEach(tab => {
        if (event[this.templateKeys[tab]]) {
          let changed = []

          event[this.templateKeys[tab]].forEach(perm => {
            const values = this.handleValues(perm.contexts)

            if (!comparison) this.data[tab].template = values

            const comparedValues = this.compareTemplate(values, tab, perm.id)

            Object.keys(comparedValues).forEach(key => {
              changed.push({
                columnID: perm.id,
                type: key,
                value: values[key],
              })
            })
          })

          if (changed.length) {
            if (comparison) {
              this.data[tab].comparison = structuredClone(changed)
            } else {
              this.data[tab].changedItems = structuredClone(changed)

              if (this.data[tab].columns.length)
                this.setTemplateTable(tab, changed)
            }
          }
        }
      })
    },

    compareTemplate(values, tab, columnID) {
      let payload = {}
      const index = this.data[tab].columns.findIndex(el => el.id === columnID)

      if (index >= 0) {
        Object.keys(values).forEach(key => {
          const result = this.data[tab].columns[index].values[key]
          if (values[key] !== result) payload = { ...payload, [key]: result }
        })
      }

      return payload
    },

    setTemplateTable(tab, changed) {
      this.$refs[`toggle-table-${tab}`][0].changed = structuredClone(changed)
      this.data[tab].changedItems = structuredClone(changed)

      changed.forEach(el => {
        const index = this.$refs[`toggle-table-${tab}`][0].allColumns.findIndex(
          col => col.id === el.columnID
        )

        this.$refs[`toggle-table-${tab}`][0].allColumns[index].values[el.type] =
          el.value
      })
    },

    handleDeleteTemplate(event) {
      if (this.$refs.DeleteTemplate) {
        this.$refs.DeleteTemplate.handleOpenDialog(event)
      }
    },

    async deleteTemplateSubmit(event) {
      const id = event.id
      await deleteTemplate(id)
        .then(() => {
          this.handleAlert({
            messageAlert: this.$t(
              'PermissionsPage.deleteTemplate.alert.success'
            ),
            statusAlert: 'success',
          })
          this.$refs.templatesMenu.checkSelected(id)
          this.$refs.DeleteTemplate.$refs.modal.dialog = false
          this.openCloseTemplateMenu(true)
        })
        .catch(error => {
          const alertPayload = alertErrorMessage(
            this.$t('PermissionsPage.deleteTemplate.alert.error'),
            error
          )

          this.displayAlert(alertPayload)
          this.$refs.DeleteTemplate.$refs.modal.dialog = false
          this.openCloseTemplateMenu(true)
        })
    },

    openCloseTemplateMenu(value) {
      this.$refs.templatesMenu.menuDrop = value
    },
    async editTemplate(event) {
      try {
        const id = event.id
        const label = event.label
        delete event.id

        const payload = {
          templateID: id,
          body: {
            ...event,
            label,
          },
        }

        await updateTemplate(payload)
        const alertPayload = alertSuccessMessage(
          this.$t('PermissionsPage.addNewTemplate.alert.successUpdate')
        )

        this.displayAlert(alertPayload)

        this.$refs.templatesMenu.$refs.editableListItems.clearEdit()
        this.getTemplates()
      } catch (error) {
        const alertPayload = alertErrorMessage(
          this.$t('PermissionsPage.addNewTemplate.alert.errorUpdate'),
          error
        )
        this.getTemplates()
        this.displayAlert(alertPayload)
      }
    },
  },
}
</script>

<style lang="scss" scoped src="./style.scss" />
