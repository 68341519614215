import enterpriseApi from '@/services/http-enterprise'
import { constructParams } from '@/helpers/api'

export async function getNotifications({
  limit = 20,
  offset = 0,
  seen = null,
  startDate = null,
  endDate = null,
}) {
  const params = constructParams(
    [
      'limit',
      'offset',
      'sortOrder',
      'sortBy',
      'title',
      'creatorIDs',
      'accessProfileIDs',
      'roleIDs',
      'personIDs',
      'ownersEntityHierarchiesIDs',
      'status',
      'type',
      'channel',
      'startDate',
      'endDate',
      'mine',
      'seen',
    ],
    {
      limit,
      offset,
      sortBy: 'sentAt',
      sortOrder: 'desc',
      mine: true,
      seen,
      startDate,
      endDate,
    }
  )

  return (await enterpriseApi()).post('/notifications', params)
}

export async function markAsRead(id) {
  return (await enterpriseApi()).put(`/performance/notifications/${id}/reading`)
}

export async function markAsUnread(id) {
  return (await enterpriseApi()).delete(
    `/performance/notifications/${id}/reading`
  )
}

export async function markAllAsRead() {
  return (await enterpriseApi()).put(
    '/performance/notifications/bulk/reading/all'
  )
}

export async function postNotification(data) {
  return (await enterpriseApi()).post(`/performance/notifications`, data)
}
export async function avancedNotificationSearch(data) {
  const params = constructParams(
    [
      'limit',
      'offset',
      'sortOrder',
      'sortBy',
      'title',
      'creatorIDs',
      'accessProfileIDs',
      'roleIDs',
      'personIDs',
      'ownersEntityHierarchiesIDs',
      'status',
      'type',
      'channel',
      'startDate',
      'endDate',
      'mine',
      'seen',
      'cycleID',
    ],
    data
  )

  return (await enterpriseApi()).post(`/notifications`, params)
}

export async function getNotificationSendChannels(data) {
  const params = constructParams(
    ['limit', 'offset', 'entityType', 'entityClass', 'label'],
    data
  )

  return (await enterpriseApi()).get(`/notification-send-channels`, {
    params,
  })
}

export async function duplicateNotification(notificationID) {
  return (await enterpriseApi()).post(
    `/performance/notifications/${notificationID}/duplicate`
  )
}

export async function getNotification(notificationID) {
  return (await enterpriseApi()).get(`/notifications/${notificationID}`)
}

export async function putPerformanceNotification(notificationID, payload) {
  return (await enterpriseApi()).put(
    `/performance/notifications/${notificationID}`,
    payload
  )
}

export async function deleteNotification(notificationID) {
  return (await enterpriseApi()).delete(
    `/performance/notifications/${notificationID}`
  )
}
