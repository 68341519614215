export const management = 'management'

export const management_groups = 'management.groups'
export const management_groups_create = 'management.groups.creator'
export const management_groups_edit = 'management.groups.edit'
export const management_groups_edit_name = 'management.groups.edit.name'
export const management_groups_edit_costCenter =
  'management.groups.edit.costCenter'
export const management_groups_edit_people = 'management.groups.edit.people'
export const management_groups_edit_subgroups =
  'management.groups.edit.subgroups'
export const management_groups_delete = 'management.groups.delete'
export const management_groups_move = 'management.groups.move'
export const management_groups_duplicate = 'management.groups.duplicate'
export const management_groups_activate = 'management.groups.activate'
export const management_groups_inactivate = 'management.groups.inactivate'
export const management_groups_export_xlsx = 'management.groups.export.xlsx'

export const management_permissions = 'management.permissions'
export const management_permissions_create = 'management.permissions.create'
export const management_permissions_edit = 'management.permissions.edit'
export const management_permissions_delete = 'management.permissions.delete'

export const management_users = 'management.users'
export const management_users_create = 'management.users.creator'
export const management_users_delete = 'management.users.delete'
export const management_users_edit = 'management.users.edit'
export const management_users_edit_detail = 'management.users.edit.detail'
export const management_users_edit_groups = 'management.users.edit.groups'
export const management_users_edit_attributes =
  'management.users.edit.attributes'
export const management_users_activate = 'management.users.activate'
export const management_users_inactivate = 'management.users.inactivate'
export const management_users_resent = 'management.users.resent'
export const management_users_export_xlsx = 'management.users.export.xlsx'

export const management_attributes = 'management.attributes'
export const management_attributes_create = 'management.attributes.create'
export const management_attributes_edit = 'management.attributes.edit'
export const management_attributes_delete = 'management.attributes.delete'

export const management_integrations = 'management.integrations'
export const management_integrations_slack_edit =
  'management.integrations.slack.edit'
export const management_integrations_slack_delete =
  'management.integrations.slack.delete'

export const management_audit_logs = 'management.audit-logs'

export const management_engagement = 'management.engagement'
export const management_engagement_survey_edit =
  'management.engagement.survey.edit'

export const management_permissions_permission_groups =
  'management.permissions.permission-groups'

export const management_performance = 'management.performance'
export const management_performance_entities = 'management.performance.entities'

export const management_performance_group_roles =
  'management.performance.group-roles'
export const management_performance_group_roles_create =
  'management.performance.group-roles.create'
export const management_performance_group_roles_edit =
  'management.performance.group-roles.edit'
export const management_performance_group_roles_delete =
  'management.performance.group-roles.delete'

export const management_performance_entities_access_profiles =
  'management.performance.entities.access-profiles'
export const management_performance_entities_access_profiles_create =
  'management.performance.entities.access-profiles.create'
export const management_performance_entities_access_profiles_edit =
  'management.performance.entities.access-profiles.edit'
export const management_performance_entities_access_profiles_delete =
  'management.performance.entities.access-profiles.delete'

export const management_performance_metadata = 'management.performance.metadata'
export const management_performance_metadata_create =
  'management.performance.metadata.create'
export const management_performance_metadata_edit =
  'management.performance.metadata.edit'
export const management_performance_metadata_delete =
  'management.performance.metadata.delete'
export const management_performance_metadata_order =
  'management.performance.metadata.order'

export const management_performance_metadata_groups =
  'management.performance.metadata-groups'
export const management_performance_metadata_groups_create =
  'management.performance.metadata-groups.create'
export const management_performance_metadata_groups_edit =
  'management.performance.metadata-groups.edit'
export const management_performance_metadata_groups_delete =
  'management.performance.metadata-groups.delete'
export const management_performance_metadata_groups_order =
  'management.performance.metadata-groups.order'

export const management_performance_cycle = 'management.performance.cycle'
export const management_performance_cycle_create =
  'management.performance.cycle.create'
export const management_performance_cycle_edit =
  'management.performance.cycle.edit'
export const management_performance_cycle_delete =
  'management.performance.cycle.delete'
export const management_performance_cycle_order =
  'management.performance.cycle.order'
export const management_performance_default_fields =
  'management.performance.default-fields'
export const management_performance_default_fields_edit =
  'management.performance.default-fields.edit'

export const management_performance_series_planned =
  'management.performance.series-planned'
export const management_performance_series_planned_create =
  'management.performance.series-planned.create'
export const management_performance_series_planned_delete =
  'management.performance.series-planned.delete'

export const management_performance_cycle_general_settings =
  'management.performance.cycle.general-settings'

export const management_performance_cycle_selection =
  'management.performance.cycle.selection'

export const management_performance_module = 'management.performance.module'

export const management_performance_goals_edit =
  'management.performance.goals.edit'

export const management_performance_kpi_edit = 'management.performance.kpi.edit'

export const management_performance_measurementModel =
  'management.performance.measurementModel'

export const management_performance_measurementModel_edit =
  'management.performance.measurementModel.edit'

export const management_permissions_module_configuration =
  'management.permissions.module-configuration'

export const management_permissions_module_engagement =
  'management.permissions.module-engagement'

export const management_permissions_module_growth =
  'management.permissions.module-growth'

export const management_permissions_module_performance =
  'management.permissions.module-performance'

export const management_permissions_module_notification =
  'management.permissions.module-notification'

export const management_permissions_module_profile =
  'management.permissions.module-profile'

export const management_permissions_module_custom_survey =
  'management.permissions.module-custom-survey'

export const management_permissions_module_rewards =
  'management.permissions.module-rewards'
