// https://firebase.google.com/docs/reference/js/auth.md#auth_package
import { initializeApp } from 'firebase/app'
// import { getAnalytics, logEvent } from 'firebase/analytics'

import {
  browserLocalPersistence,
  browserSessionPersistence,
  createUserWithEmailAndPassword,
  EmailAuthProvider,
  fetchSignInMethodsForEmail,
  getAuth,
  getIdTokenResult,
  GoogleAuthProvider,
  linkWithCredential,
  OAuthProvider,
  setPersistence,
  signInWithCustomToken,
  RecaptchaVerifier,
  signInWithCredential,
  signInWithEmailAndPassword,
  signInWithPopup,
} from 'firebase/auth'

/** @type {import('firebase/app').FirebaseOptions} */
const firebaseConfig = {
  apiKey: process.env.VUE_APP_FB_APIKEY,
  appId: process.env.VUE_APP_FB_APPID,
  authDomain: process.env.VUE_APP_FB_AUTHDOMAIN,
  measurementId: process.env.VUE_APP_FB_MEASUREMENT,
  messagingSenderId: process.env.VUE_APP_FB_MESSAGING,
  projectId: process.env.VUE_APP_FB_PROJECTID,
  storageBucket: process.env.VUE_APP_FB_STORAGE,
}

// init firebase
initializeApp(firebaseConfig)

// let analytics
// Initialize Analytics and get a reference to the service
// EXEMPLE: logEvent(analytics, 'notification_received')
// if (process.env.NODE_ENV === 'production') {
//   analytics = getAnalytics(app)
// }

const projectAuth = getAuth()
projectAuth.useDeviceLanguage()
projectAuth.tenantId = process.env.VUE_APP_FB_TENANTID

const LocalStorageToken = 'teamculture:legacy'

export {
  browserLocalPersistence,
  browserSessionPersistence,
  createUserWithEmailAndPassword,
  EmailAuthProvider,
  fetchSignInMethodsForEmail,
  getIdTokenResult,
  GoogleAuthProvider,
  linkWithCredential,
  OAuthProvider,
  projectAuth,
  setPersistence,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  signInWithPopup,
  LocalStorageToken,
  RecaptchaVerifier,
  signInWithCredential,
}
