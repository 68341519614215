<template>
  <div>
    <DetailSectionAccordion
      type="settings"
      :title="$t('purposeConfigSection.title')"
      :opened="true"
    >
      <div>
        <v-row class="custom-row">
          <div>
            <v-icon size="0.875rem" class="icon-row">mdi-trending-up</v-icon>
            <span>{{ $t('purposeConfigSection.options.increase') }}</span>
          </div>

          <div>
            <v-tooltip bottom min-width="22rem">
              <template #activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <CustomSwitch
                    :value="form.increase"
                    :readonly="true"
                    @input="setValue($event, 'increase')"
                  ></CustomSwitch>
                </div>
              </template>

              <div>
                <span>{{ $t('purposeConfigSection.tooltipText') }}</span>
              </div>
            </v-tooltip>
          </div>
        </v-row>

        <v-row class="custom-row">
          <div>
            <v-icon size="0.875rem" class="icon-row">mdi-trending-down</v-icon>
            <span>{{ $t('purposeConfigSection.options.decrease') }}</span>
          </div>

          <div>
            <v-tooltip bottom min-width="22rem">
              <template #activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <CustomSwitch
                    :value="form.decrease"
                    :readonly="true"
                    @input="setValue($event, 'decrease')"
                  ></CustomSwitch>
                </div>
              </template>

              <div>
                <span>{{ $t('purposeConfigSection.tooltipText') }}</span>
              </div>
            </v-tooltip>
          </div>
        </v-row>

        <v-row class="custom-row">
          <div>
            <v-icon size="0.875rem" class="icon-row">fi-rr-calendar</v-icon>
            <span>{{ $t('purposeConfigSection.options.deliveryDate') }}</span>
          </div>

          <div>
            <CustomSwitch
              :readonly="readonly"
              :value="form.deliverable"
              @input="setValue($event, 'deliverable')"
            ></CustomSwitch>
          </div>
        </v-row>
      </div>
    </DetailSectionAccordion>
  </div>
</template>
<script>
export default {
  name: 'PorposeConfig',
  props: {
    readonly: {
      type: Boolean,
      default: false,
      required: true,
    },
    settingsByEntity: {
      type: Object,
      default: () => ({}),
      required: true,
    },
  },

  data() {
    return {
      interval: null,
      form: {
        increase: true,
        decrease: true,
        deliverable: true,
      },
    }
  },
  watch: {
    settingsByEntity() {
      this.handleSettings()
    },
  },

  methods: {
    calcPayload() {
      const payload = structuredClone(this.settingsForm)
      delete payload.id

      const newPayload = {
        ...payload,
        ...(!!payload?.suggestedCycle?.id && {
          suggestedCycle: { id: payload.suggestedCycle.id },
        }),
        ...(!payload?.disabledMeasurements && {
          disabledMeasurements: [],
        }),
        ...(!payload?.disabledPurposes && {
          disabledPurposes: [],
        }),
        ...(!!payload?.suggestedAccessProfile?.id && {
          suggestedAccessProfile: { id: payload.suggestedAccessProfile.id },
        }),
        ...(!!payload?.suggestedGoalGroup?.id && {
          suggestedGoalGroup: { id: payload.suggestedGoalGroup.id },
        }),
        goalGroupAccessProfiles: payload?.goalGroupAccessProfiles?.map(e => ({
          id: e.id,
        })),
      }

      if (!payload?.suggestedCycle?.id) newPayload.suggestedCycle = null

      newPayload.disabledPurposes = Object.keys(this.form).filter(
        key => !this.form[key]
      )

      return newPayload
    },
    async handleSettings() {
      this.settingsForm = this.settingsByEntity

      this.settingsByEntity?.disabledPurposes?.forEach(purposes => {
        if (Object.prototype.hasOwnProperty.call(this.form, purposes)) {
          this.form[purposes] = false
        }
      })
    },
    setValue(value, field) {
      this.form[field] = value

      this.interval = setTimeout(() => {
        this.submit()
      }, 300)
    },
    async submit() {
      const payload = this.calcPayload()
      this.$emit('submit', payload)
    },
  },
}
</script>
<style src="./style.scss" lang="scss" scoped />
