import Vue from 'vue'

import router from './router'
import store from './store'

import '@/plugins/bignumber'
import '@/plugins/casl'
import '@/plugins/mask'
import i18n from '@/plugins/i18n'

import App from './App.vue'
import '@/styles/styles.scss'

import '@/plugins/ui-components'
import '@/plugins/apexchart'
import vuetify from '@/plugins/vuetify'
import unhead from '@/plugins/unhead'

import { projectAuth } from '@/firebase/config'

Vue.config.productionTip = false
Vue.prototype.CDN_BASE_URL = process.env.VUE_APP_CDN_ASSETS_BASE_URL

let app = ''
// initialize app after firebase setup
projectAuth.onAuthStateChanged(async () => {
  if (!app) {
    app = new Vue({
      store,
      i18n,
      router,
      vuetify,
      unhead,
      render: h => h(App),
    }).$mount('#app')
  }
})
