var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"data-test-entities-configurtions":""}},[_c('DetailSectionAccordion',{attrs:{"type":"settings","title":_vm.$t('PerformanceTab.EntitiesConfigurations.title'),"opened":true}},[_vm._l((_vm._settings),function(configuration){return [(
          configuration.type === 'v-checkbox' &&
          configuration.id !== 'goalEnabled'
        )?_c('v-checkbox',{key:configuration.id,staticClass:"custom-checkbox",attrs:{"id":configuration.id,"data-test-configuration":configuration.id,"label":_vm.$t(
            `PerformanceTab.EntitiesConfigurations.settings.${configuration.id}`
          ),"value":_vm._formConfigurationId,"color":"#dd0041"},on:{"change":function($event){return _vm.handleSettings(configuration, $event)}}}):_vm._e()]}),_c('div',{staticClass:"cycle-selection-content",attrs:{"data-test-cycle-selection":""}},[(_vm.loading)?_c('v-skeleton-loader',{staticClass:"skeleton-loading",attrs:{"type":"button"}}):_c('ItemSelector',{ref:"cycleInput",staticClass:"selected-field",attrs:{"input-config":{
          label: _vm.$t(
            'PerformanceTab.EntitiesConfigurations.settings.definitionCycle'
          ),
          showAvatar: false,
          closeOnSelect: true,
        },"menu-config":{
          attach: true,
          showTabs: false,
        },"current-value":_vm.currentCycle,"menu-options":_vm._cycleOptions,"infinity-scroll":true,"persistent":true,"watch-current":true},on:{"update:item":function($event){return _vm.handleCycleChange($event)},"infinity:scroll":function($event){return _vm.handleCycleScroll($event)},"search:item":function($event){return _vm.searchCycle($event)},"focus:input":function($event){_vm.searchCycles = []}}}),_c('v-tooltip',{attrs:{"min-width":"800","max-width":"800","bottom":"","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"tooltip-icon",attrs:{"data-test-definition-cycle":"","size":"0.75rem"}},'v-icon',attrs,false),on),[_vm._v(" fi-rr-info ")])]}}])},[_c('div',{attrs:{"data-test-definition-cycle-tooltip":""},domProps:{"innerHTML":_vm._s(
            _vm.$t(
              'PerformanceTab.EntitiesConfigurations.settings.definitionCycleTooltip'
            )
          )}})])],1),_c('div',{staticClass:"access-profile-selection-content",attrs:{"data-test-access-profile-selection":""}},[(_vm.loading)?_c('v-skeleton-loader',{staticClass:"skeleton-loading",attrs:{"type":"button"}}):_c('ItemSelector',{ref:"accessProfileDefault",staticClass:"selected-field",attrs:{"input-config":{
          label: _vm.$t(
            'PerformanceTab.EntitiesConfigurations.settings.defaultAccessProfile'
          ),
          showAvatar: false,
          closeOnSelect: true,
        },"menu-config":{
          attach: true,
          showTabs: false,
        },"current-value":_vm.currentAccessProfile,"menu-options":_vm._accessProfiles,"infinity-scroll":true,"persistent":false,"multiple":false,"fill-width":true,"watch-current":true,"loading":_vm.accessProfileFieldLoading},on:{"update:item":_vm.handleAccessProfileChange,"infinity:scroll":_vm.handleAccessProfileScroll,"search:item":_vm.handleSearchAccessProfile,"focus:input":_vm.handleFocusAccessProfile}}),_c('v-tooltip',{attrs:{"min-width":"800","max-width":"800","bottom":"","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"tooltip-icon",attrs:{"data-test-definition-access-profile":"","size":"0.75rem"}},'v-icon',attrs,false),on),[_vm._v(" fi-rr-info ")])]}}])},[_c('div',{attrs:{"data-test-definition-access-profile-tooltip":""},domProps:{"innerHTML":_vm._s(
            _vm.$t(
              'PerformanceTab.EntitiesConfigurations.settings.defaultAccessProfileTooltip'
            )
          )}})])],1),(_vm._isGoalEnabledVisible)?_c('div',{staticClass:"border"},[(_vm._isGoalEnabledVisible)?_c('v-checkbox',{staticClass:"custom-checkbox",attrs:{"data-test-configuration":"goalEnabled","label":_vm.$t('PerformanceTab.EntitiesConfigurations.settings.goalEnabled'),"value":_vm._goalEnabled},on:{"change":_vm.handleGoalEnabledChange}}):_vm._e(),_c('div',{staticClass:"goal-group"},[(_vm.loading)?_c('v-skeleton-loader',{staticClass:"skeleton-loading",attrs:{"type":"button"}}):_c('ItemSelector',{ref:"suggestedGoalGroup",staticClass:"selected-field",attrs:{"input-config":{
            label: _vm.$t(
              'PerformanceTab.EntitiesConfigurations.defaultGoalGroup'
            ),
            showAvatar: false,
            closeOnSelect: true,
            clearable: true,
          },"menu-config":{
            attach: true,
            showTabs: false,
          },"current-value":_vm._currentDefaultGoalGroup,"menu-options":_vm._defaultGoalGroupOptions,"loading":_vm.loadingGoals,"infinity-scroll":true,"watch-current":true,"disabled":!_vm._goalEnabled,"readonly":!_vm._goalEnabled,"multiple":false},on:{"update:item":function($event){return _vm.handleDefaultGoalGroupChange($event)},"infinity:scroll":function($event){return _vm.handleGoalGroupScroll($event)},"search:item":function($event){return _vm.searchGoalGroup($event)}}}),_c('v-tooltip',{attrs:{"min-width":"800","max-width":"800","bottom":"","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"tooltip-icon",attrs:{"data-test-definition-roles":"","size":"0.75rem"}},'v-icon',attrs,false),on),[_vm._v(" fi-rr-info ")])]}}],null,false,1601837383)},[_c('div',{attrs:{"data-test-definition-roles-tooltip":""},domProps:{"innerHTML":_vm._s(
              _vm.$t(
                'PerformanceTab.EntitiesConfigurations.suggestedGoalGroupTooltip'
              )
            )}})])],1),_c('div',{staticClass:"goal-selection-content",attrs:{"data-test-roles-selection":""}},[(_vm.loading)?_c('v-skeleton-loader',{staticClass:"skeleton-loading",attrs:{"type":"button"}}):_vm._e(),_c('ItemSelector',{ref:"RolesUsedInGoalsInput",staticClass:"selected-field",attrs:{"input-config":{
            label: _vm.$t(
              'PerformanceTab.EntitiesConfigurations.rolesUsedInGoals'
            ),
            showAvatar: false,
            clearable: true,
            rules: _vm.settingsForm?.goalGroupAccessProfiles ? _vm.rules : [],
          },"menu-config":{
            attach: true,
            showTabs: false,
          },"current-value":_vm._currentGoalGroupAccessProfiles,"menu-options":_vm._rolesOptions,"watch-current":true,"multiple":true,"infinity-scroll":true,"disabled":!_vm._goalEnabled,"readonly":!_vm._goalEnabled},on:{"update:item":_vm.handleRolesChange,"search:item":function($event){return _vm.searchGetRoles($event)},"infinity:scroll":function($event){return _vm.handleRolesScroll($event)}}}),_c('v-tooltip',{attrs:{"min-width":"800","max-width":"800","bottom":"","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"tooltip-icon",attrs:{"data-test-definition-roles":"","size":"0.75rem"}},'v-icon',attrs,false),on),[_vm._v(" fi-rr-info ")])]}}],null,false,1601837383)},[_c('div',{attrs:{"data-test-definition-roles-tooltip":""},domProps:{"innerHTML":_vm._s(_vm.$t('PerformanceTab.EntitiesConfigurations.rolesTooltip'))}})])],1)],1):_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }